type DisableTabIndex = () => void;

const disableTabIndex: DisableTabIndex = () => {
  const elementsWithTabIndex: NodeListOf<HTMLElement> = document.querySelectorAll('[tabindex]');

  elementsWithTabIndex.forEach((element: HTMLElement) => {
    element.removeAttribute('tabindex');
  });

  // Prevent the tab key from moving focus to the next element
  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
    }
  });
};

export { disableTabIndex };
export {};
