import { useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { Button, Container, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ErrorIcon } from '../../../icons/ErrorIcon';
import Info from './Info.png';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      justifyContent: 'space-between',
    },
    /* 
    The size of the image is explicitly stated here so that this content can properly be centered vertically
    before the image is loaded.
    */
    illustration: {
      width: '284px',
      height: '284px',
      [theme.breakpoints.down('md')]: {
        width: '200px',
        height: '200px',
      },
    },
  })
);

export function UserInfo() {
  const { dispatch, state } = useAppStateContext();
  const [userId, setId] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const classes = useStyles();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch({ type: 'set-user-info', userInfo: { userId, email } });
    dispatch({ type: 'next-pane' });
  };

  return (
    <Container>
      <Grid container alignItems="center" className={classes.gridContainer}>
        <Grid item lg={5}>
          {state.appIsExpired ? (
            <>
              <Typography variant="h1" gutterBottom>
                <ErrorIcon />
                App has expired.
              </Typography>

              <Typography variant="body1" gutterBottom>
                Please re-deploy the application and try again.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1" gutterBottom>
                Your details
              </Typography>

              <Typography variant="body1" gutterBottom>
                Please enter your details below so we can attach this report to your User ID.
              </Typography>
            </>
          )}
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" justifyContent="center" alignItems="flex-start">
              <TextField
                variant="outlined"
                placeholder="Host/Speaker ID"
                style={{ marginBottom: '2em', width: '100%' }}
                type="text"
                name="id"
                onChange={(e) => setId(e.target.value.trim())}
                value={userId}
              />
              <TextField
                variant="outlined"
                placeholder="Email address"
                style={{ marginBottom: '2em', width: '100%' }}
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                value={email}
                required
              />
            </Grid>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginBottom: '1em' }}
              disabled={email === ''}
            >
              Next
            </Button>
          </form>
        </Grid>

        <Grid item lg={5}>
          <img src={Info} alt="Hello" className={classes.illustration} />
          <Typography variant="body1" color="textSecondary" gutterBottom>
            <strong>Not sure what your Host/Speaker id is?</strong> Please check with the service team for more
            information.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
